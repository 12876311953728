
import { Component, Vue } from 'vue-property-decorator'
import Echarts from 'echarts'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { MapList, MapBindDeviceList, DeviceInfo } from '@/types/intelligentControl'

@Component
export default class PondMonitorMap extends Vue {
  private onlineImg = require('@/assets/icon/intelligentControl/online.svg')
  private onlineImgCursor = require('@/assets/icon/intelligentControl/online2.svg')
  private offImg = require('@/assets/icon/intelligentControl/offline.svg')
  private offImgCursor = require('@/assets/icon/intelligentControl/offline2.svg')
  private defaultImg = require('@/assets/img/intelligentControl/default.png')
  private defaultBigImg = require('@/assets/img/intelligentControl/defaultBig.png')
  private map: AMap.Map | any = null
  private markers: AMap.Marker[] = []
  private bindMarkers: AMap.Marker[] = []
  private drawer = false
  private tableData: DeviceInfo[] = []
  private drawerDataParent: MapList = {
    deviceTypeId: '',
    // 设备id
    deviceId: '',
    // 设备名称
    deviceName: '',
    // 控制器串号
    deviceNumber: '',
    // 设备状态
    deviceStatus: '',
    // 经度
    longitude: '',
    // 纬度
    latitude: ''
  }

  // 绑定子设备
  private drawerDataBindDevice: MapBindDeviceList = {
    id: '',
    deviceId: '',
    deviceTypeId: '',
    // 需要绑定的设备名称
    deviceName: '',
    // 遥控串号
    controlDeviceNumber: '',
    // 控制器设备ID
    controlDeviceId: '',
    // 控制器路数
    controlRoute: '',
    // 开关
    controlState: '',
    // 图片
    fileImg: '',
    longitude: '',
    latitude: ''
  }

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  get drawerDataBak () {
    return {
      deviceTypeId: '',
      // 设备id
      deviceId: '',
      // 设备名称
      deviceName: '',
      // 控制器串号
      deviceNumber: '',
      // 设备状态
      deviceStatus: '',
      // 经度
      longitude: '',
      // 纬度
      latitude: ''
    }
  }

  get drawerDataBindDeviceBak () {
    return {
      id: '',
      deviceId: '',
      deviceTypeId: '',
      // 需要绑定的设备名称
      deviceName: '',
      // 遥控串号
      controlDeviceNumber: '',
      // 控制器设备ID
      controlDeviceId: '',
      // 控制器路数
      controlRoute: '',
      // 开关
      controlState: '',
      // 图片
      fileImg: '',
      longitude: '',
      latitude: ''
    }
  }

  created () {
    this.getProjectLocation()
  }

  // 页面离开
  destroyed () {
    if (this.map) {
      if (this.markers.length > 0) {
        this.map.remove(this.markers)
        this.markers = []
      }
      this.clearBindMarks()
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 环形图
  onlineEchart (presentPie: { onlineAmount: string; offLineAmount: string }) {
    const pieEchart: any = Echarts.init(this.$refs.onlineEcharts as any)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${presentPie.onlineAmount}` : `${item}  ${presentPie.offLineAmount}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '在线',
          value: presentPie.onlineAmount
        }, {
          name: '离线',
          value: presentPie.offLineAmount
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  // 加载地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      map.on('click', this.drawerClose)
      // 绘制
      drawPolygon(map, list || [], null, this.drawerClose)
      this.map = map
      this.getData()
    })
  }

  getData () {
    this.$axios.get(this.$apis.intelligentControl.selectControl, {
      projectId: this.projectId
    }).then(res => {
      this.onlineEchart({ onlineAmount: res.onlineAmount, offLineAmount: res.offLineAmount })
      // 将设备marker添加到地图上
      res.devices.map((item: any) => {
        if (item.deviceTypeId === '1025') {
          this.addDeviceMarks(item)
        } else {
          this.addBindDeviceMarks(item)
        }
      })
    })
  }

  // 展示绑定设备
  addDeviceMarks (item: MapList) {
    const lnglat = [Number(item.longitude), Number(item.latitude)]
    const marker: any = new AMap.Marker({
      icon: new AMap.Icon({
      // 图标的取图地址
        image: this.getIcon(item, false)
      }),
      offset: new AMap.Pixel(-18, -48),
      position: new AMap.LngLat(lnglat[0], lnglat[1]),
      extData: item
    })
    marker.on('click', (e: any) => {
      this.clickMarker(e)
    })
    this.map.add(marker)
    this.markers.push(marker)
  }

  // 展示绑定设备
  addBindDeviceMarks (item: MapBindDeviceList) {
    const lnglat = [Number(item.longitude), Number(item.latitude)]
    // 创建点实例
    const marker = new AMap.Marker({
      position: new AMap.LngLat(lnglat[0], lnglat[1]),
      offset: new AMap.Pixel(-65, -112),
      content: this.getIconContent(item, false),
      extData: item
    })
    marker.on('click', (e: any) => {
      this.clickMarker(e)
    })
    this.map.add(marker)
    this.bindMarkers.push(marker)
  }

  // 点击marker
  clickMarker (e: any) {
    const detail = e.target.getExtData()
    // 判断设备类型
    if (detail.deviceTypeId === '1025') {
      this.clickParentMarker(detail, e)
    } else {
      this.clickBindMarker(detail, e)
    }
  }

  clickParentMarker (detail: MapList, e: any) {
    // 判断点击的是否是当前抽屉的设备
    if (this.drawerDataParent.deviceId !== detail.deviceId) {
      if (this.drawer) {
        this.drawerClose()
      }
      this.getDeviceDetail(detail, (res: any) => {
        // 改变图标
        this.drawer = true
        this.drawerDataParent = res
        this.handleTable(res)
        e.target.setOffset(new AMap.Pixel(-22, -64))
        e.target.setIcon(new AMap.Icon({
          image: this.getIcon(detail, true)
        }))
      })
    }
  }

  // 获取控制器绑定表格信息
  handleTable (detail: MapList) {
    this.tableData = []
    if (detail.do1Name) {
      this.tableData.push({
        deviceId: detail.deviceId,
        controlRoute: '1',
        name: detail.do1Name,
        state: detail.do1State
      })
    }
    if (detail.do2Name) {
      this.tableData.push({
        deviceId: detail.deviceId,
        controlRoute: '2',
        name: detail.do2Name,
        state: detail.do2State
      })
    }
    if (detail.do3Name) {
      this.tableData.push({
        deviceId: detail.deviceId,
        controlRoute: '3',
        name: detail.do3Name,
        state: detail.do3State
      })
    }
    if (detail.do4Name) {
      this.tableData.push({
        deviceId: detail.deviceId,
        controlRoute: '4',
        name: detail.do4Name,
        state: detail.do4State
      })
    }
  }

  clickBindMarker (detail: MapBindDeviceList, e: any) {
    // 判断点击的是否是当前抽屉的设备
    if (this.drawerDataBindDevice.id !== detail.deviceId) {
      if (this.drawer) {
        this.drawerClose()
      }
      this.getDeviceDetail(detail, (res: any) => {
        // 改变图标
        this.drawer = true
        this.drawerDataBindDevice = res
        e.target.setContent(this.getIconContent(detail, true))
      })
    }
  }

  // 获取详情
  getDeviceDetail (detail: MapBindDeviceList | MapList, callback: Function) {
    this.$axios.get(this.$apis.intelligentControl.selectControlById, {
      deviceId: detail.deviceId,
      deviceTypeId: detail.deviceTypeId
    }).then(res => {
      callback(res)
    })
  }

  // 获取图标 bigShow:是否点击状态
  getIcon (data: any, bigShow: boolean) {
    const cursorIndex = bigShow ? 1 : 0
    let state = 1 // 默认离线
    const icon = [[this.onlineImg, this.offImg],
      [this.onlineImgCursor, this.offImgCursor]]
    state = data.deviceStatus === '1' ? 0 : 1
    return icon[cursorIndex][state]
  }

  // 获取弹框
  getIconContent (item: MapBindDeviceList, isClick: boolean) {
    const infoWindowContent =
    `<div class="custom-infowindow ${isClick ? 'click' : ' '}">
      <img src=${item.fileImg ? item.fileImg.filePrefix + item.fileImg.fileUrl : this.defaultImg}></img>
      <div class="text">${item.deviceName}</div>
    </div>`
    return infoWindowContent
  }

  // 父设备抽屉关闭
  drawerParentClose () {
    if (this.drawerDataParent.deviceId !== '') {
      this.drawer = false
      // 恢复未点击状态图标
      const marker: AMap.Marker[] = this.markers.filter(item => item.getExtData().deviceId === this.drawerDataParent.deviceId)
      if (marker.length > 0) {
        marker[0].setOffset(new AMap.Pixel(-18, -48))
        marker[0].setIcon(this.getIcon(marker[0].getExtData(), false))
      }
      this.drawerDataParent = this.drawerDataBak
    }
  }

  // 绑定设备抽屉关闭
  drawerBindDeviceClose () {
    if (this.drawerDataBindDevice.id !== '') {
      this.drawer = false
      // 恢复未点击状态图标
      const marker: AMap.Marker[] = this.bindMarkers.filter(item => item.getExtData().deviceId === this.drawerDataBindDevice.id)
      if (marker.length > 0) {
        marker[0].setContent(this.getIconContent(marker[0].getExtData(), false))
      }
      this.drawerDataBindDevice = this.drawerDataBindDeviceBak
    }
  }

  drawerClose () {
    this.drawerBindDeviceClose()
    this.drawerParentClose()
  }

  // 清除绑定设备
  clearBindMarks () {
    if (this.bindMarkers.length > 0) {
      this.map && this.map.remove(this.bindMarkers)
      this.bindMarkers = []
    }
  }

  // 操作开关
  operateSwitch (detail: DeviceInfo, callback: Function) {
    this.$axios.post(this.$apis.intelligentControl.insertControl, {
      deviceId: detail.deviceId,
      operateRoute: detail.controlRoute,
      operateCommand: detail.state
    }).then(() => {
      this.$message.success(`${this.getText(detail.state)}成功!`)
    }).catch(() => {
      callback()
    })
  }

  // 获取状态
  getText (state: string) {
    const data = ['关闭', '开启']
    return data[+state]
  }

  // 开关
  onSwitch (info: DeviceInfo) {
    const oldValue = info.state === '1' ? '0' : '1'
    this.$confirm(`确认${this.getText(info.state)}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.operateSwitch(info, () => {
        info.state = oldValue
      })
    }).catch(() => {
      info.state = oldValue
    })
  }

  onSwitchBindDevice (state: string) {
    const oldValue = state === '1' ? '0' : '1'
    this.$confirm(`确认${this.getText(state)}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const info = this.drawerDataBindDevice
      this.operateSwitch({ deviceId: info.controlDeviceId, state: info.controlState, controlRoute: info.controlRoute }, () => {
        this.drawerDataBindDevice.controlState = oldValue
      })
    }).catch(() => {
      this.drawerDataBindDevice.controlState = oldValue
    })
  }

  // 历史数据
  toHistory (val: number) {
    this.$router.push({
      name: 'intelligentControlMapHistory',
      query: { controlRoute: this.drawerDataBindDevice.controlRoute || '', controlDeviceNumber: this.drawerDataParent.deviceNumber || this.drawerDataBindDevice.controlDeviceNumber },
      params: { deviceId: val === 0 ? this.drawerDataParent.deviceId : this.drawerDataBindDevice.controlDeviceId || '', projectId: this.projectId }
    })
  }
}
